import React from 'react'
import { Link } from 'gatsby'
import Codepen from '../../components/embedCodepen'
import Layout from '../../components/layout'
import Header from '../../components/header'
import { Helmet } from 'react-helmet';





const Portfolio1 = ({data}) => (
  <Layout>
      <Codepen/>
      <main className="page--demo demo--rgbcmyk">
      <Helmet>
        <title>{data.site.siteMetadata.demoRgbTitle} | {data.site.siteMetadata.title}</title>
      </Helmet>
        <section className="slab slab--main">
            <Header siteTitle="Rob DiMarzo" /> 

            <div className="slab__inner">
                <div className="pageblock__content">   
                  <div className="pageblock__title">
                      <h1>Visualizing RGB & CMYK</h1>
                  </div>  
                    <p className="text--bold">A demo to visualize how RGB and CMYK work to create additive or subtractive color.</p> 
                    <p>
                        <strong>RGB (Red, Green, Blue)</strong> is an additive color model - meaning that combining the colors "add" to their light spectrum. This model is carried through light and has uses in monitors,lighting, photography, etc.

                        When you combine red, green, and blue equally, you will produce white. 
                    </p>
                    <p>
                        <strong>CMYK (Cyan, Magenta, Yellow, Key)</strong> is a subtractive color model - meaning that combining the colors "subtract" from the their light spectrum. This model has uses in ink printing. Since printing in black is very common, the additional color "Key" is black to produce a deep black without having to waste ink on combining colors. 

                         When you combine cyan, magenta, and yellow equally, you will produce black. 
                    </p>
                    <p>
                    Created with only CSS (using SCSS) for mix-blend-mode and animation.                   </p>
                </div>
                <div className="pageblock__demo">
                <p data-height="700" data-theme-id="36208" data-slug-hash="jYogvK" data-default-tab="result" data-user="robdimarzo" data-pen-title="Visualizing RGB and CMYK Colors" className="codepen">See the Pen <a href="https://codepen.io/robdimarzo/pen/jYogvK/">Visualizing RGB and CMYK Colors</a> by Rob DiMarzo (<a href="https://codepen.io/robdimarzo">@robdimarzo</a>) on <a href="https://codepen.io">CodePen</a>.</p>
                </div>
            </div>
        </section>
    </main>
  </Layout>
)
export const query = graphql`
  query RGBQuery {
    site {
      siteMetadata {
        title
        demoRgbTitle
      }
    }
  }
`
export default Portfolio1
